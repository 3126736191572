import React from 'react'
import office_1 from "../../assets/office_1.png"
import office_2 from "../../assets/office_2.png"
import office_3 from "../../assets/office_3.png"

export default function Office() {
    return (
        <div className='office'>
            <div className='office__informations'>
                <h2>Mon cabinet</h2>
                <p>Situé idéalement à Villeneuve-lès-Bouloc, mon cabinet de naturopathie est un lieu paisible où vous pouvez échapper à l'agitation de la vie urbaine pour vous reconnecter avec votre santé, votre vitalité et votre harmonie intérieure.</p>
                <p>Venez me rendre visite et découvrez comment la naturopathie peut transformer votre vie en vous aidant à retrouver votre vitalité naturelle et votre bien-être.</p>
            </div>

            <div className='office__wrap-img'>
                <img alt="Mon cabinet" className='office__img2' src={office_2}></img>
                <img alt="Mon cabinet" className='office__img3' src={office_3}></img>
            </div>
        </div>
    )
}
