import React from 'react'
import { Link } from 'react-router-dom'

export default function Meet() {
    return (
        <div className='meet'>
            <Link to="/contact" className='meet__button'>
                <span>Prendre rendez-vous</span>
            </Link>
        </div>
    )
}
