import './App.css';
import Header from './component/header/header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Naturopathie from './pages/service/naturopathie';
import Massage from './pages/service/massage';
import Magnetisme from './pages/service/magnetisme';
import Presentation from './pages/presentation/presentation';
import Contact from './pages/contact/contact';
import Footer from './component/footer/footer';
import Mentions from './pages/mentions/mentions';

function App() {
  return (
    <BrowserRouter basename="/" >

      <Routes>

        <Route path="/naturopathie" element={<><Header /><Naturopathie /><Footer /></>} />
        <Route path="/massages" element={<><Header /><Massage /><Footer /></>} />
        <Route path="/magnetisme" element={<><Header /><Magnetisme /><Footer /></>} />


        <Route path="/qui-suis-je" element={<><Header /><Presentation /><Footer /></>} />
        <Route path="/contact" element={<><Header /><Contact /><Footer /></>} />
        <Route path="/mentions-legales" element={<><Header /><Mentions /><Footer /></>} />

        <Route path="/" element={<><Header /><Home /><Footer /></>} />
        <Route path="/*" element={<><Header /><Home /><Footer /></>} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
