import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='footer'>

            <div className='container'>
                <div className='footer__wrap'>
                    <div>
                        <Link to="/contact" className='footer_rdv'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M17.875 3.4375H4.125C3.7453 3.4375 3.4375 3.7453 3.4375 4.125V17.875C3.4375 18.2547 3.7453 18.5625 4.125 18.5625H17.875C18.2547 18.5625 18.5625 18.2547 18.5625 17.875V4.125C18.5625 3.7453 18.2547 3.4375 17.875 3.4375Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.125 2.0625V4.8125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.875 2.0625V4.8125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3.4375 7.5625H18.5625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span>Prendre un rendez-vous</span>
                        </Link>
                    </div>
                    <div className='footer_items'>
                        <Link to={"/naturopathie"}>Naturopathie</Link>
                        <Link to={"/massages"}>Massages</Link>
                        <Link to={"/magnetisme"}>Magnétisme</Link>
                    </div>
                </div>

                <hr></hr>

                <div className='footer_items'>
                    <h6 className='footer_name'>Karine Brugier</h6>
                    <Link className='footer_mentions' to={"mentions-legales"}>Mentions légales</Link>
                </div>
            </div>
        </div>
    )
}
