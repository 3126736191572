import React from 'react'
import naturopathie from "../../assets/services/narutopathie.png"
import Todo from '../../component/todo/todo'

export default function Naturopathie() {
    return (
        <div className='container'>
            <div className='service'>
                <div>
                    <h1>La naturopathie</h1>

                    <p>La naturopathie est un ensemble de méthodes de soins visant à renforcer les défenses de l’organisme par des moyens naturels. En tant que naturopathe, je me considère comme un éducateur de santé. </p>

                    <p>Lors de notre première rencontre, je réalise un bilan complet de votre situation, en discutant de <span>vos problèmes</span> de santé, de <span>vos habitudes de vie</span>, de <span>vos antécédents médicaux</span> et de <span>votre alimentation</span>.</p>

                    <p>À la suite de ce bilan sur votre situation, nous identifierons votre objectif principal afin d’élaborer un programme personnalisé pour améliorer votre santé et votre hygiène de vie au quotidien.</p>

                    <p>Le programme se base sur différentes technique 100% naturels tels que la gemmothérapie, la phytothérapie, les huiles essentielles ou encore le massage.</p>

                    <p>L’objectif est de <b>renforcer votre capacité à vivre en bonne santé !</b></p>

                    <Todo
                        title="À qui s’adresse mes séances de Naturopathie ?"
                        options={[
                            "Vous avez des troubles digestifs (ballonnement, constipation, intestin irritable)",
                            "Vous êtes une femme avec des troubles du cycle menstruel, endométriose, trouble de la fertilité, ménopauses",
                            "Vous êtes fatigué, stressé, angoissé ou vous avez des troubles du sommeil",
                            "Vous avez une maladie chronique, des troubles ORL, des douleurs",
                            "Vous avez des problèmes cutanés, acné, eczéma",
                            "Vous avez des problèmes de poids, cardiovasculaire, de thyroïde"
                        ]}
                    />
                </div>
                <div>
                    <img alt='Naturopathie' src={naturopathie}></img>
                </div>
            </div>
        </div>
    )
}
