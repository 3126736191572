import React from 'react'

import karine from "../../assets/karine.png"

export default function Presentation() {
    return (
        <div className='container'>
            <div className='presentation'>
                <h1>Qui suis-je ?</h1>
                <div className='presentation__wrap'>
                    <div>
                        <img alt='Karine Brugier' src={karine}></img>
                    </div>
                    <div>
                        <p>Passionnée par les médecines alternatives et le bien-être. J’ai appris par moi-même et j’ai pu acquérir des connaissances grâce à une formation en Naturopathie, ainsi que pour le massage et le magnétisme.</p>
                        <p>{"J’ai choisi le chemin de ce métier ayant été moi-même concernée par la maladie.\nJe vous accompagne dans votre protocole de soins et de bien-être."}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
