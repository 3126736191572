import React from 'react'

export default function Messenger() {
    return (
        <div className='messenger'>
            <p>Une question ? Vous pouvez m’envoyer un message sur Messenger :</p>
            <a href='https://m.me/100070158630792' target='_blank' rel="noreferrer" className='messenger__link'>
                <div className='messenger__link-wrap'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                        <g clip-path="url(#clip0_232_874)">
                            <path d="M14.5312 0.453125C6.59976 0.453125 0.453125 6.24973 0.453125 14.0792C0.453125 18.1743 2.13592 21.7132 4.87506 24.1578C5.34801 24.5832 5.25059 24.8295 5.33102 27.456C5.33735 27.6399 5.3886 27.8196 5.48029 27.9792C5.57199 28.1388 5.70134 28.2736 5.85708 28.3717C6.01281 28.4698 6.19019 28.5284 6.37375 28.5422C6.5573 28.5561 6.74146 28.5248 6.91016 28.4512C9.90701 27.1314 9.94553 27.0272 10.4536 27.1654C19.136 29.5551 28.5469 23.9986 28.5469 14.0792C28.5469 6.24973 22.4631 0.453125 14.5312 0.453125ZM22.9842 10.939L18.8494 17.485C18.6933 17.7311 18.488 17.9424 18.2464 18.1055C18.0048 18.2686 17.7321 18.38 17.4455 18.4328C17.1588 18.4856 16.8643 18.4786 16.5805 18.4123C16.2966 18.346 16.0295 18.2218 15.7959 18.0474L12.5063 15.5852C12.3592 15.4749 12.1803 15.4153 11.9965 15.4153C11.8127 15.4153 11.6338 15.4749 11.4867 15.5852L7.04779 18.952C6.45533 19.4011 5.67936 18.6914 6.07867 18.0644L10.2134 11.5184C10.3695 11.2722 10.5748 11.0609 10.8164 10.8978C11.058 10.7346 11.3307 10.6231 11.6173 10.5703C11.904 10.5175 12.1986 10.5245 12.4824 10.5909C12.7663 10.6573 13.0334 10.7816 13.2669 10.956L16.5555 13.4176C16.7026 13.5279 16.8814 13.5875 17.0653 13.5875C17.2491 13.5875 17.428 13.5279 17.575 13.4176L22.0162 10.0543C22.6075 9.60228 23.3835 10.3114 22.9842 10.939Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_232_874">
                                <rect width="29" height="29" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span>Karine BRUGIER</span>
                </div>
            </a>
        </div>
    )
}
