import React from 'react'
import home from "../../assets/home.png"

export default function Carousel({ title }) {
    return (
        <div className='carousel' style={{ backgroundImage: `url(${home})` }}>
            <h3>{title}</h3>
            <div className='carousel_opacity'></div>
        </div>
    )
}
