import React from 'react'
import { Link } from 'react-router-dom'


function Card({ title, explication, modifier, link }) {
    return (
        <div className={`business__card business--${modifier}`}>
            <h2>{title}</h2>
            <p>{explication}</p>
            <Link to={link}>En savoir plus</Link>
        </div>
    )
}

export default function BusinessCard() {
    return (
        <div className='business__wrap'>
            <Card
                modifier="naturopathie"
                title={"Naturopathie"}
                explication={"La naturopathie est un ensemble de méthodes de soins visant à renforcer les défenses de l’organisme par des moyens naturels."}
                link={"/naturopathie"}
            />
            <Card
                modifier="massage"
                title={"Massages intuitifs et contés"}
                explication={"Les massages intuitifs placent l'intuition au cœur de cette approche. Et les massages contés envoient une impulsion à votre inconscient qui se met en quête d’une réparation de soi."}
                link={"/massages"}
            />
            <Card
                modifier="magnetisme"
                title={"Magnétisme"}
                explication={"Le magnétisme utilise l’énergie pour soulager le corps et l’esprit par l’imposition des mains."}
                link={'/magnetisme'}
            />
        </div>
    )
}
