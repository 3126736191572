import React from 'react'

export default function Mentions() {
    return (
        <div className='container mentions'>
            <p>Nom du site : Karine BRUGIER - Naturopathe, praticienne en massage et magnétiseuse</p>
            <p>Adresse du site : https://karine-brugier.fr</p>
            <p>Responsable de la publication : BILLIET Rudy - rudy.26.fr@gmail.fr</p>
            <p>Hébergement du site et des médias : Netlify</p>
        </div>
    )
}
