import React, { useEffect, useState } from 'react'
import logo_artichaud from "../../assets/logo_artichaut.png"
import { Link, useLocation } from 'react-router-dom'

export default function Header() {
    let location = useLocation()

    let [active, setActive] = useState(false);

    useEffect(() => {
        setActive(false)
    }, [location])

    return (
        <header className='header'>
            <div className='container'>
                <div className='header__informations'>
                    <img src={logo_artichaud} alt="Artichaud"></img>
                    <div>
                        <h1>Karine Brugier</h1>
                        <p>Naturopathe, praticienne en massage et magnétiseuse</p>
                    </div>
                </div>


                <div className='header__menu header__desktop'>
                    <div className='header__desktop__items-left'>
                        <Link to="/" className='header__link'>Accueil</Link>

                        <div className='header__select'>
                            <div className='header__select_title'>
                                <span>Services</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512" fill='#FFF'><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
                            </div>
                            <div className='header__select_options'>
                                <Link to="/naturopathie">Naturopathie</Link>
                                <Link to="/massages">Massages</Link>
                                <Link to="/magnetisme">Magnétisme</Link>
                            </div>
                        </div>

                        <Link to="/qui-suis-je" className='header__link'>Qui suis-je ?</Link>
                    </div>
                    <div className='header__desktop__items-right'>
                        <Link to="/contact" className='header__link'>Prendre un rendez-vous</Link>
                    </div>
                </div>

                <div className='header__menu header__mobile' onClick={() => setActive(!active)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="22" width="19" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                    <span>Menu</span>
                </div>
            </div>


            {active === true && <div className='header__full-width'>
                <div className='header__full-width_header'>
                    <div onClick={() => setActive(!active)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="30" width="22" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                </div>
                <div className='header__full-width__wrap'>
                    <Link to="/">Accueil</Link>
                    <Link to="/naturopathie">Naturopathie</Link>
                    <Link to="/massages">Massages</Link>
                    <Link to="/magnetisme">Magnétisme</Link>
                    <Link to="/qui-suis-je">Qui suis-je ?</Link>
                    <Link to="/contact">Prendre un rendez-vous</Link>
                </div>
            </div>}


        </header>
    )
}
