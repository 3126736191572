import React from 'react'


function Stars({ note }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="13" viewBox="0 0 80 13" fill="none">
            <path d="M76.5582 12.5C76.4032 12.5 76.249 12.4523 76.1162 12.3574L72.9951 10.1154L69.874 12.3574C69.7446 12.4506 69.5892 12.5005 69.4299 12.4999C69.2706 12.4994 69.1156 12.4484 68.9869 12.3544C68.8581 12.2608 68.7621 12.129 68.7124 11.9776C68.6628 11.8262 68.662 11.663 68.7103 11.5112L69.8743 7.74815L66.7812 5.56496C66.653 5.4702 66.5578 5.33746 66.5091 5.18554C66.4603 5.03361 66.4605 4.87017 66.5096 4.71835C66.5591 4.56689 66.6548 4.43484 66.7832 4.34085C66.9117 4.24685 67.0664 4.19568 67.2255 4.19455L71.0564 4.18879L72.2761 0.519125C72.3264 0.367952 72.423 0.236471 72.552 0.143303C72.6811 0.0501342 72.8362 0 72.9953 0C73.1544 0 73.3094 0.0501342 73.4385 0.143303C73.5676 0.236471 73.6641 0.367952 73.7145 0.519125L74.9134 4.18879L78.7639 4.19455C78.9232 4.19545 79.0781 4.2466 79.2067 4.34073C79.3353 4.43487 79.431 4.5672 79.4803 4.71893C79.5295 4.87065 79.5297 5.03406 79.481 5.18594C79.4322 5.33781 79.3369 5.47043 79.2086 5.56496L76.1154 7.74815L77.2795 11.5112C77.3279 11.663 77.3272 11.8262 77.2776 11.9776C77.228 12.129 77.132 12.2608 77.0033 12.3544C76.8742 12.4492 76.7183 12.5002 76.5582 12.5Z" fill={note >= 5 ? "#E879F9" : "#F5D0FE"} />
            <path d="M59.4059 12.5C59.2509 12.5 59.0966 12.4523 58.9639 12.3574L55.8427 10.1154L52.7216 12.3574C52.5923 12.4506 52.4369 12.5005 52.2776 12.4999C52.1183 12.4994 51.9632 12.4484 51.8346 12.3544C51.7058 12.2608 51.6097 12.129 51.5601 11.9776C51.5104 11.8262 51.5097 11.663 51.558 11.5112L52.722 7.74815L49.6289 5.56496C49.5007 5.4702 49.4055 5.33746 49.3567 5.18554C49.308 5.03361 49.3082 4.87017 49.3572 4.71835C49.4067 4.56689 49.5024 4.43484 49.6309 4.34085C49.7594 4.24685 49.9141 4.19568 50.0732 4.19455L53.9041 4.18879L55.1238 0.519125C55.1741 0.367952 55.2706 0.236471 55.3997 0.143303C55.5288 0.0501342 55.6838 0 55.8429 0C56.002 0 56.1571 0.0501342 56.2862 0.143303C56.4152 0.236471 56.5118 0.367952 56.5621 0.519125L57.7611 4.18879L61.6116 4.19455C61.7708 4.19545 61.9258 4.2466 62.0544 4.34073C62.183 4.43487 62.2787 4.5672 62.3279 4.71893C62.3771 4.87065 62.3774 5.03406 62.3286 5.18594C62.2798 5.33781 62.1845 5.47043 62.0562 5.56496L58.9631 7.74815L60.1271 11.5112C60.1755 11.663 60.1749 11.8262 60.1253 11.9776C60.0757 12.129 59.9797 12.2608 59.8509 12.3544C59.7218 12.4492 59.5659 12.5002 59.4059 12.5Z" fill={note >= 4 ? "#E879F9" : "#F5D0FE"} />
            <path d="M42.2535 12.5C42.0985 12.5 41.9443 12.4523 41.8115 12.3574L38.6904 10.1154L35.5693 12.3574C35.44 12.4506 35.2846 12.5005 35.1253 12.4999C34.966 12.4994 34.8109 12.4484 34.6822 12.3544C34.5535 12.2608 34.4574 12.129 34.4077 11.9776C34.3581 11.8262 34.3573 11.663 34.4056 11.5112L35.5697 7.74815L32.4765 5.56496C32.3483 5.4702 32.2531 5.33746 32.2044 5.18554C32.1557 5.03361 32.1558 4.87017 32.2049 4.71835C32.2544 4.56689 32.3501 4.43484 32.4786 4.34085C32.607 4.24685 32.7617 4.19568 32.9208 4.19455L36.7517 4.18879L37.9714 0.519125C38.0217 0.367952 38.1183 0.236471 38.2474 0.143303C38.3764 0.0501342 38.5315 0 38.6906 0C38.8497 0 39.0048 0.0501342 39.1338 0.143303C39.2629 0.236471 39.3594 0.367952 39.4098 0.519125L40.6087 4.18879L44.4592 4.19455C44.6185 4.19545 44.7734 4.2466 44.902 4.34073C45.0306 4.43487 45.1263 4.5672 45.1756 4.71893C45.2248 4.87065 45.2251 5.03406 45.1763 5.18594C45.1275 5.33781 45.0322 5.47043 44.9039 5.56496L41.8108 7.74815L42.9748 11.5112C43.0232 11.663 43.0225 11.8262 42.9729 11.9776C42.9233 12.129 42.8273 12.2608 42.6986 12.3544C42.5695 12.4492 42.4136 12.5002 42.2535 12.5Z" fill={note >= 3 ? "#E879F9" : "#F5D0FE"} />
            <path d="M25.1012 12.5C24.9462 12.5 24.7919 12.4523 24.6592 12.3574L21.5381 10.1154L18.4169 12.3574C18.2876 12.4506 18.1322 12.5005 17.9729 12.4999C17.8136 12.4994 17.6586 12.4484 17.5299 12.3544C17.4011 12.2608 17.305 12.129 17.2554 11.9776C17.2057 11.8262 17.205 11.663 17.2533 11.5112L18.4173 7.74815L15.3242 5.56496C15.196 5.4702 15.1008 5.33746 15.0521 5.18554C15.0033 5.03361 15.0035 4.87017 15.0526 4.71835C15.102 4.56689 15.1977 4.43484 15.3262 4.34085C15.4547 4.24685 15.6094 4.19568 15.7685 4.19455L19.5994 4.18879L20.8191 0.519125C20.8694 0.367952 20.9659 0.236471 21.095 0.143303C21.2241 0.0501342 21.3792 0 21.5382 0C21.6973 0 21.8524 0.0501342 21.9815 0.143303C22.1106 0.236471 22.2071 0.367952 22.2574 0.519125L23.4564 4.18879L27.3069 4.19455C27.4661 4.19545 27.6211 4.2466 27.7497 4.34073C27.8783 4.43487 27.974 4.5672 28.0232 4.71893C28.0725 4.87065 28.0727 5.03406 28.0239 5.18594C27.9752 5.33781 27.8798 5.47043 27.7515 5.56496L24.6584 7.74815L25.8225 11.5112C25.8709 11.663 25.8702 11.8262 25.8206 11.9776C25.771 12.129 25.675 12.2608 25.5462 12.3544C25.4172 12.4492 25.2612 12.5002 25.1012 12.5Z" fill={note >= 2 ? "#E879F9" : "#F5D0FE"} />
            <path d="M10.0333 12.5C9.87909 12.5 9.72565 12.4523 9.59359 12.3574L6.48864 10.1154L3.38368 12.3574C3.25504 12.4506 3.10044 12.5005 2.94197 12.4999C2.78349 12.4994 2.62924 12.4484 2.50124 12.3544C2.37312 12.2608 2.27756 12.129 2.22815 11.9776C2.17875 11.8262 2.17801 11.663 2.22605 11.5112L3.38406 7.74815L0.306971 5.56496C0.179445 5.4702 0.084737 5.33746 0.036243 5.18554C-0.0122511 5.03361 -0.0120763 4.87017 0.0367427 4.71835C0.0859468 4.56689 0.18118 4.43484 0.308982 4.34085C0.436785 4.24685 0.590693 4.19568 0.748955 4.19455L4.56002 4.18879L5.77337 0.519125C5.82345 0.367952 5.91948 0.236471 6.04789 0.143303C6.17629 0.0501342 6.33056 0 6.48883 0C6.6471 0 6.80136 0.0501342 6.92977 0.143303C7.05817 0.236471 7.15421 0.367952 7.20429 0.519125L8.39703 4.18879L12.2276 4.19455C12.386 4.19545 12.5401 4.2466 12.6681 4.34073C12.796 4.43487 12.8912 4.5672 12.9402 4.71893C12.9892 4.87065 12.9894 5.03406 12.9409 5.18594C12.8924 5.33781 12.7976 5.47043 12.6699 5.56496L9.59283 7.74815L10.7508 11.5112C10.799 11.663 10.7983 11.8262 10.749 11.9776C10.6997 12.129 10.6041 12.2608 10.476 12.3544C10.3476 12.4492 10.1925 12.5002 10.0333 12.5Z" fill={note >= 1 ? "#E879F9" : "#F5D0FE"} />
        </svg>
    )
}


function Card({ name, text, note, source }) {
    return (
        <article className='feedback__card'>
            <div className='feedback__card_header'>
                <h3>{name}</h3>
                <Stars note={note} />
            </div>
            <p className='feedback__card_text'>{text}</p>
            <p className='feedback__card_source'>Via {source}</p>
        </article>
    )
}

export default function Feedback() {
    return (
        <div className='feedback'>
            <h2>Témoignages</h2>

            <div className='feedback__wrap'>

                <Card
                    name="Corinne Doumeng"
                    text="Madame Brugier est très professionnelle et à l'écoute de ses patients, soulage mes maux avec ses séances de magnétisme cela me fait un bien fou. Je recommande les yeux fermés 👍"
                    note={5}
                    source="Google"
                />

                <Card
                    name="Fanny Mazzoleni"
                    text="C’est mon premier rendez-vous et j’ai beaucoup apprécié notre rencontre. Très à l’écoute, de bon conseils. Karine nous met à l’aise et elle est très pro. Je la recommande à 1000%"
                    note={5}
                    source="Google"
                />

                <Card
                    name="Laure G."
                    text="Pour ma consultation en nathuropathie Karine à été très à l'écoute et m'a donné pleins de conseils ainsi qu'un protocole adapté à mes problèmes. Je vous invite à essayer également le massage relaxant qui m'a vraiment détendu ! Merci !!!"
                    note={5}
                    source="Google"
                />

            </div>
        </div>
    )
}
