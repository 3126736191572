import React from 'react'
import Messenger from '../../component/messenger/messenger'

export default function Contact() {
    return (
        <div className='container contact'>
            <div>
                <h1>Prendre rendez-vous</h1>
                <p>Prenez dès à présent rendez-vous pour votre première consultation et bénéficiez d'un premier bilan de santé. </p>
                <p>Vous pouvez me rencontrer dans mon cabinet exclusivement sur rendez-vous.</p>

                <div className='contact__phone'>
                    <p>Prise de rendez-vous :</p>
                    <div className='contact__phone-wrap'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path d="M4.28571 0C1.92188 0 0 1.92188 0 4.28571V25.7143C0 28.0781 1.92188 30 4.28571 30H25.7143C28.0781 30 30 28.0781 30 25.7143V4.28571C30 1.92188 28.0781 0 25.7143 0H4.28571ZM10.3594 6.47545C11.0089 6.30134 11.692 6.62946 11.9464 7.25223L13.2857 10.4665C13.5134 11.0156 13.3527 11.6451 12.8973 12.0201L11.25 13.3728C12.3616 15.7299 14.2701 17.6384 16.6272 18.75L17.9799 17.096C18.3549 16.6406 18.9844 16.4799 19.5335 16.7076L22.7478 18.0469C23.3705 18.308 23.6987 18.9844 23.5246 19.6339L22.721 22.5804C22.5603 23.1696 22.0312 23.5714 21.4286 23.5714C13.1451 23.5714 6.42857 16.8549 6.42857 8.57143C6.42857 7.96875 6.83036 7.43973 7.41295 7.27902L10.3594 6.47545Z" fill="#374151" />
                        </svg>
                        <a href='tel:0676087633'>+33 6 76 08 76 33</a>
                    </div>
                </div>

                <Messenger />
            </div>
            <div className='contact__map'>
                <iframe width="100%" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=1.4170324802398684%2C43.76620501323429%2C1.4215117692947388%2C43.767907617684706&amp;layer=mapnik&amp;marker=43.76705632151735%2C1.4192721247673035" title="Carte du Cabinet"></iframe>
                <p>711 Route d’Ensarla - 31620 Villeneuve les bouloc</p>
            </div>
        </div>
    )
}
