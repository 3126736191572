import React from 'react'

export default function Todo({ title, options = [], icon = "check" }) {
    return (
        <div className='todo'>
            <h2>{title}</h2>
            <div>
                {options.map((e) => {
                    return (
                        <div className='todo__option'>
                            {icon === "check" && <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M27 9.00049L13 22.9999L6 16.0005" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>}

                            {icon === "arrow" && <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M15.125 8.9375L19.25 13.0625L15.125 17.1875" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.75 4.8125C2.75 7.00054 3.61919 9.09896 5.16637 10.6461C6.71354 12.1933 8.81196 13.0625 11 13.0625H19.25" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>}
                            <p>{e}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
