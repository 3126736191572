import React from 'react'
import Carousel from '../../component/carousel/carousel'
import BusinessCard from '../../component/businessCard/businessCard'
import Office from '../../component/office/office'
import Feedback from '../../component/feedback/feedback'
import Meet from '../../component/meet/meet'

export default function Home() {
    return (
        <div className='container'>
            <Carousel
                title={"Prenez soin de votre santé naturellement"}
            />

            <BusinessCard />

            <Office />

            <Feedback />

            <Meet />
        </div>
    )
}
