import React from 'react'
import massage from "../../assets/services/massage.png"
import Todo from '../../component/todo/todo'

export default function Massage() {
    return (
        <div className='container'>
            <div className='service'>
                <div>
                    <h1>Massages intuitifs et contés</h1>

                    <p>Les massages intuitifs placent l'intuition au cœur de cette approche. Je masse avec ma sensibilité et je ressens les besoins de la personne sous mes mains.</p>

                    <p>Les massages contés allient le massage et le conte thérapeutique. Le conte permet d'envoyer une impulsion à votre inconscient de ce fait l'esprit se met en quête de processus de réparation de soi.</p>

                    <p>Les massages s’adressent aussi bien aux adultes qu’aux enfants.</p>

                    <p><span>Les deux pratiques liées</span> <b>apportent un bien-être apparent</b>.</p>

                    <Todo
                        title={"Bienfait des massages"}
                        icon='arrow'
                        options={[
                            "Lutte contre le stress et l’anxiété",
                            "Améliore la circulation sanguine et apaise les douleurs",
                            "Libère les énergies",
                            "Apporte détente et lâcher prise"
                        ]}
                    />

                </div>
                <div>
                    <img alt='Naturopathie' src={massage}></img>
                </div>
            </div>
        </div>
    )
}
