import React from 'react'
import magnetisme from "../../assets/magnetisme.jpeg"

export default function Magnetisme() {
    return (
        <div className='container'>
            <div className='service'>
                <div>
                    <h1>Le magnétisme</h1>

                    <p>Le magnétisme permet de donner un soin à une personne en utilisant un fluide énergétique spécifique, qui agit sur le corps et le mental</p>

                </div>
                <div>
                    <img alt='Naturopathie' src={magnetisme}></img>
                </div>
            </div>
        </div>
    )
}
